import React from 'react'
import Layout from '@/components/layout'
import { SEO } from '@/components/seo'
import H3 from '@/components/H3'
import { img } from '@/tools/image'
import { PageProps } from 'gatsby'

const title = '施設案内'

const room1Images = [
  'nursery/1st_floor_1.jpg',
  'nursery/1st_floor_2.jpg',
  'nursery/1st_floor_3.jpg',
  'nursery/1st_floor_4.jpg',
]
const room2Images = ['nursery/room2_1.jpg', 'nursery/room2_2.jpg', 'nursery/toy_1.jpg']
const learningRoomImages = ['nursery/learning_room_1.jpg', 'nursery/learning_room_2.jpg']

const Nursery = (props: PageProps) => (
  <Layout title={title}>
    <SEO title={title} pathname={props.location.pathname} />
    <div className="text-md md:text-lg">
      <section className="playroom mb-10">
        <H3 text="支援室１" />
        <p className="mb-2">
          童謡やキーボードのリズムに合わせた音楽活動の中で、コミュニケーション力、場面の切り替え、感覚統合と音楽のはたらきを基にして心身の育成を目指します。
        </p>
        <p>また、絵本や童謡などの読み聞かせや工作などの制作活動の場として利用します。</p>
        <div className="grid grid-cols-2 gap-4 mt-5">
          {room1Images.map((path, i) => (
            <div key={i} className="relative pb-2/3">
              <img className="absolute h-full w-full object-cover" src={img(path)} />
            </div>
          ))}
        </div>
      </section>

      <section className="playroom mb-10">
        <H3 text="支援室２" />
        <p>
          知育玩具や絵本などの遊びを通して、一人一人ゆっくりと関わり成長を見守りながら、言葉・習慣を身に付けられるように支援します。
        </p>
        <div className="grid grid-cols-2 gap-4 mt-5">
          {room2Images.map((path, i) => (
            <div key={i} className="relative pb-2/3">
              <img className="absolute h-full w-full object-cover" src={img(path)} />
            </div>
          ))}
        </div>
      </section>

      <section className="playroom mb-10">
        <H3 text="学習室・支援室" />
        <p>
          ひとりひとり違いのあるお子様に対し、担当指導員中心にゆっくりと関わりあいながら、学校の日々の課題、手作り工作、いろいろな遊びを通した生活の質の向上などの「育つ力」を大切に支援いていきます。
        </p>
        <div className="grid grid-cols-2 gap-4 mt-5">
          {learningRoomImages.map((path, i) => (
            <div key={i} className="relative pb-2/3">
              <img className="absolute h-full w-full object-cover" src={img(path)} />
            </div>
          ))}
        </div>
      </section>
    </div>
  </Layout>
)

export default Nursery
