import React from 'react'

interface Props {
  text: string
  wrapStyle?: any
}

const H3: React.FC<Props> = ({ text, wrapStyle }) => (
  <div style={wrapStyle}>
    <h3 className="pt-1 pb-2 mb-5 border border-b-2 border-t-0 border-l-6 border-r-0 border-purple-300">
      <div className="text-xl ml-5 font-bold">{text}</div>
    </h3>
  </div>
)

H3.defaultProps = {
  wrapStyle: {},
}

export default H3
